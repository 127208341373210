import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    MobileCashFlow: () => import("./mobile/MobileCashFlow/MobileCashFlow.vue"),
    DesktopCashFlow: () =>
      import("./desktop/DesktopCashFlow/DesktopCashFlow.vue"),
  },
})
export default class CashFlowView extends Vue {}
